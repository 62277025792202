const initialState = {
  galleryImageClicked: false,
  categoryImageUrl: "",
  categoryImagesArray: [0, 1, 2, 3, 4, 5, 6, 7],
  categoryImageClicked: 0
};

const setGalleryImageClicked = (state = initialState, action) => {
  switch (action.type) {
    case "SET_GALLERY_IMAGE_CLICKED":
      let data = action.payload;
      if (data !== undefined) {
        return {
          ...state,
          galleryImageClicked: !state.galleryImageClicked,
          categoryImageUrl: data.categoryImageUrl,
          categoryImagesArray: data.categoryImagesArray,
          categoryImageClicked: data.categoryImageClicked
        };
      } else {
        return {
          ...state,
          galleryImageClicked: !state.galleryImageClicked
        };
      }

    default:
      return state;
  }
};

export default setGalleryImageClicked;
