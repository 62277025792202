const initialState = {
  carouselPosition: [0, 0, 0]
};

const setCarouselPosition = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CAROUSEL_POSITION":
      return {
        ...state,
        carouselPosition: state.carouselPosition.map((position, index) => {
          if (position === action.payload[index]) {
            return position;
          } else {
            return action.payload[index];
          }
        })
      };
    default:
      return state;
  }
};

export default setCarouselPosition;
