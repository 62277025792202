import React, { Component } from "react";
import { connect } from "react-redux";
import { setClickedContent, setTourLink } from "../actions";

import mapText from "../data/mapTextData";
import tourLinks from "../data/tourLinksData";

export class CategoryTour extends Component {
  // openFullScreen = () => {
  //   if (this.props.browserName !== "safari/webkit") {
  //   }
  // };

  render() {
    if (this.props.contentType === "tour")
      return (
        <div className="window-content window-content-tour">
          <div className="container">
            <div className="warning-text">
              <div className="line">{mapText.virtualTourWarning1[this.props.languageIndex]}</div>
              <div className="line">{mapText.virtualTourWarning2[this.props.languageIndex]}</div>
            </div>
            <div
              className="btn btn-confirm"
              onClick={() => {
                this.props.setTourLink(tourLinks[this.props.categoriesContentIndex]);
                this.props.setClickedContent("");
              }}
            >
              {mapText.buttonConfirm[this.props.languageIndex]}
            </div>
          </div>
        </div>
      );
    else return null;
  }
}

const mapStateToProps = (state) => {
  return {
    languageIndex: state.setLanguage.languageIndex,
    categoriesContentIndex: state.setClickedContent.categoriesContentIndex,
    contentType: state.setClickedContent.contentType
  };
};

const mapDispatchToProps = {
  setClickedContent,
  setTourLink
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryTour);
