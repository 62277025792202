import { combineReducers } from "redux";
import setLanguage from "./setLanguage";
import playIntroAudio from "./playIntroAudioReducer";
import setClickedContent from "./setClickedContent";
import setTourLink from "./setTourLink";
import setImagesCategory from "./setImagesCategory";
import setWindowWidth from "./setWindowWidth";
import setGalleryImageClicked from "./setGalleryImageClicked";
import setCarouselPosition from "./setCarouselPosition";
import setBrowserName from "./setBrowserNameReducer";

export default combineReducers({
  setLanguage: setLanguage,
  playIntroAudio: playIntroAudio,
  setClickedContent: setClickedContent,
  setTourLink: setTourLink,
  setImagesCategory: setImagesCategory,
  setWindowWidth: setWindowWidth,
  setGalleryImageClicked: setGalleryImageClicked,
  setCarouselPosition: setCarouselPosition,
  setBrowserName: setBrowserName
});
