const legendIconNames = [
  ["Aktyvus taškas", "Active point"],
  ["Kernavės archeologinės vietovės muziejus", "The Museum of Kernavė Archeological Site"],
  ["Senosios bažnyčios vieta", "Place of the old Church"],
  ["XIII - XIV a. amatininkų kiemai", "Reconstructed craftsmen yards from the 13th-14th c."],
  ["Senojo Kernavės miesto vieta", "Place of the medieval town of Kernavė"],
  ["Kernavės dvarvietė", "Kernavė manor"],
  ["XIII - XIV a. kapinynas", "Burial ground of the 13th-14th c."],
  ["Romėniškojo laikotarpio gyvenvietės ir pilkapynas", "Roman period settlements and burial mounds"],
  ["Neolito gyvenvietės", "Neolithic settlements"],
  ["Akmens amžiaus stovyklavietės", "Campsites of the Stone Age"]
];

export default legendIconNames;
