const mapText = {
  mapTitle: ["Vilniaus Valstybinio Kernavės kultūrinio rezervato žemėlapis", "Map of Vilnius State Kernavė Cultural Reserve"],
  legendTitle: ["Legenda", "Legend"],
  buttonClose: ["Uždaryti", "Close"],
  virtualTourWarning1: ["Jūs einate į", "You are entering"],
  virtualTourWarning2: ["360° virtualų turą", "360° virtual tour"],
  buttonConfirm: ["Pradėti", "Start"],
  chooseCategory: ["Rinkites kategoriją:", "Choose category:"],
  buttonGallery: ["Nuotraukų galerija", "Photo gallery"],
  buttonTour: ["360 turas", "360 tour"],
  chooseGallery: ["Pasirinkite galerija:", "Choose gallery:"],
  buttonVideoTour: ["360 video filmai", "360 video movies"],
  buttonAbout: ["Apie", "About"]
};

export default mapText;
