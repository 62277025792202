const galleryImages = [
  [[["11_Baltasis kalnas"], ["Vaizdai"], [4], ["Baltasis kalnas", "The White Hill"]]],
  [[["9_Kernaves amatininku kiemu rekonstrukcijos"], ["Radiniai", "Vaizdai"], [16, 8]]],
  [[["10_Senosios baznycios vieta"], ["Kasinėjimai", "Radiniai", "Vaizdai"], [5, 3, 8]]],
  [
    [["1_Aukuro"], ["Kasinėjimai", "Radiniai", "Vaizdai"], [3, 7, 4], ["Aukuro kalnas", "Aukuras Hill"]],
    [["2_Mindaugo Sosto"], ["Kasinėjimai", "Radiniai", "Vaizdai"], [1, 4, 11], ["Mindaugo sosto kalnas ", "The Hill of Mindaugas’ Throne"]],
    [["3_Pilies_kalno"], ["Kasinėjimai", "Radiniai", "Vaizdai"], [3, 2, 8], ["Pilies kalnas", "Castle Hill"]],
    [["4_Lizdeikos"], ["Vaizdai"], [14], ["Lizdeikos kalnas", "The hill of Lizdeika"]]
  ],
  [[["12_Medgrinda"], ["Kasinėjimai", "Vaizdai"], [2, 4]]],
  [
    [["5_Kernave_Kriveikiškio_piliakalnis"], ["Vaizdai"], [7], ["Kernavės-Kriveikiškio piliakalnis", "Kernavė-Kriveikiškis hill-fort"]],
    [
      ["6_Kernave_Kriveikiškio_kapinynas"],
      ["Kasinėjimai", "Radiniai"],
      [5, 14],
      ["Kernavės-Kriveikiškio kapinynas", "Kernavė-Kriveikiškis cemetery"]
    ]
  ],
  [[["7_Kernavės_kapinynas"], ["Kasinėjimai", "Radiniai", "Vaizdai"], [4, 4, 6]]],
  [[["8_Semeniskiu kapinynas ir gyvenviete"], ["Kasinėjimai", "Radiniai", "Vaizdai"], [8, 7, 2]]]
];

export default galleryImages;
