import React, { Component } from "react";
import { connect } from "react-redux";
import { setGalleryImageClicked, setCarouselPosition } from "../actions";

import galleryImages from "../data/galleryImagesData";

export class CategoryGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      galleryImagesDisplayCount: 0
    };
  }

  galleryImageClicked = (imageUrl, categoryImageUrl, galleryImagesCount, imageIndex) => {
    let categoryImagesArray;
    if (galleryImagesCount !== undefined) {
      categoryImagesArray = Array.from(Array(galleryImagesCount), (_, x) => x);
    }

    let data = {
      categoryImageUrl: categoryImageUrl,
      categoryImagesArray: categoryImagesArray,
      categoryImageClicked: imageIndex
    };

    this.props.setGalleryImageClicked(data);
  };

  buttonCarouselClicked = (direction, galleryIndex, galleryImagesCount) => {
    let currentCarouselPosition = this.props.carouselPosition;
    let imageSlideLimit = galleryImagesCount * -1 + this.state.galleryImagesDisplayCount;
    if (direction === -1 && currentCarouselPosition[galleryIndex] > imageSlideLimit) {
      currentCarouselPosition[galleryIndex]--;
    } else if (direction === 1 && currentCarouselPosition[galleryIndex] < 0) {
      currentCarouselPosition[galleryIndex]++;
    }
    this.props.setCarouselPosition(currentCarouselPosition);
  };

  imagesDisplayCount = () => {
    let galleryImagesDisplayCount;
    if (this.props.windowWidth < 420) {
      galleryImagesDisplayCount = 3;
    } else {
      galleryImagesDisplayCount = 5;
    }
    this.setState({
      galleryImagesDisplayCount: galleryImagesDisplayCount
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.windowWidth !== prevProps.windowWidth) {
      this.imagesDisplayCount();
    }
  }

  componentDidMount() {
    this.imagesDisplayCount();
  }

  render() {
    const displayedImages = galleryImages[this.props.categoriesContentIndex];
    if (this.props.contentType === "gallery")
      return (
        <div className="window-content window-content-gallery">
          <div className="galleries-container-wrapper">
            {displayedImages.map((imagesCategory, imagesCategoryIndex) => {
              return (
                <div
                  className="galleries-container"
                  key={imagesCategoryIndex}
                  style={{ display: this.props.imagesCategorySelected === imagesCategoryIndex ? "flex" : "none" }}
                >
                  {imagesCategory[1].map((galleryName, index) => {
                    const galleryImagesCount = imagesCategory[2][index];
                    const imagesArray = Array.from(Array(galleryImagesCount), (_, x) => x);
                    const folderName = imagesCategory[0];
                    const imageSlideLimit = galleryImagesCount * -1 + this.state.galleryImagesDisplayCount;
                    let galleryNameTranslate = galleryName;

                    if (this.props.languageIndex === 1) {
                      switch (galleryName) {
                        case "Kasinėjimai":
                          galleryNameTranslate = "Excavations";
                          break;
                        case "Radiniai":
                          galleryNameTranslate = "Findings";
                          break;
                        case "Vaizdai":
                          galleryNameTranslate = "Views";
                          break;
                        default:
                          break;
                      }
                    }
                    return (
                      <div className="gallery-content-wrapper" key={index}>
                        {this.props.imagesCategorySelected === imagesCategoryIndex ? (
                          <div className="gallery-content" key={`content${index}`}>
                            <div className="gallery-title">{galleryNameTranslate}</div>
                            <div className="gallery-images">
                              <div className="overflow-wrapper">
                                <div
                                  className="gallery-images-carousel"
                                  style={{
                                    left:
                                      galleryImagesCount > this.state.galleryImagesDisplayCount
                                        ? `calc(${(this.props.carouselPosition[index] * 100) / this.state.galleryImagesDisplayCount}% + ${
                                            this.props.carouselPosition[index] * 1.5
                                          }px)`
                                        : "0%"
                                  }}
                                >
                                  {imagesArray.map((image, index) => {
                                    let imageSmallUrl = `"./images/gallery small/${folderName}/${galleryName}/${index + 1}.jpg"`;
                                    let imageBigUrl = `"./images/gallery/${folderName}/${galleryName}/${index + 1}.jpg"`;
                                    let categoryImagesUrl = `./images/gallery/${folderName}/${galleryName}/`;
                                    return (
                                      <div
                                        className="image"
                                        key={`image${index}`}
                                        style={{
                                          backgroundImage: `url(${imageSmallUrl})`
                                        }}
                                        onClick={() => {
                                          this.galleryImageClicked(imageBigUrl, categoryImagesUrl, galleryImagesCount, index);
                                        }}
                                      >
                                        <div className="corner-icon" style={{ backgroundImage: `url("./images/Full screen.png")` }}></div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                              {galleryImagesCount > this.state.galleryImagesDisplayCount ? (
                                <div className="carousel-buttons">
                                  <div
                                    className="btn btn-left"
                                    style={{ opacity: this.props.carouselPosition[index] === 0 ? 0.3 : 1 }}
                                    onClick={() => {
                                      this.buttonCarouselClicked(1, index, galleryImagesCount);
                                    }}
                                  ></div>
                                  <div
                                    className="btn btn-right"
                                    style={{ opacity: this.props.carouselPosition[index] === imageSlideLimit ? 0.3 : 1 }}
                                    onClick={() => {
                                      this.buttonCarouselClicked(-1, index, galleryImagesCount);
                                    }}
                                  ></div>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      );
    else return null;
  }
}

const mapStateToProps = (state) => {
  return {
    languageIndex: state.setLanguage.languageIndex,
    categoriesContentIndex: state.setClickedContent.categoriesContentIndex,
    contentType: state.setClickedContent.contentType,
    windowWidth: state.setWindowWidth.windowWidth,
    imagesCategorySelected: state.setImagesCategory.imagesCategorySelected,
    carouselPosition: state.setCarouselPosition.carouselPosition
  };
};

const mapDispatchToProps = {
  setGalleryImageClicked,
  setCarouselPosition
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryGallery);
