const videoTourText = [
  [
    ["Odininkas", "Leather worker"],
    [
      "Kernavėje daugelį dalykų tenka pasigaminti pačiam. Štai kalvis, padėjęs visus kitus darbus į šalį, pasiėmė odos. Ką jis su ja darys? Pažiūrėk šį 360° video ir sužinok. Tik nepamiršk  žvalgytis aplinkui!",
      "In Kernavė, one must make a lot of things by himself. Here, a blacksmith has put aside his other works and took up leather. What will he do with it? Watch this 360 ° video and find out. Just don’t forget to look around!"
    ]
  ],
  [
    ["Tošininkas", "Birchbark worker"],
    [
      "Tošininko brolis – rago drožėjas – ant rago plokštelių išraižė įstabius ornamentus... Kokius ir kaip? Pažiūrėk šį 360° video ir sužinok. Tik nepamiršk  žvalgytis aplinkui!",
      "The brother of a birch bark worker was a bone carver and he has carved extraordinary ornaments… What and how? Watch this 360 ° video and find out. Just don’t forget to look around!"
    ]
  ],
  [
    ["Juvelyras", "Jeweller"],
    [
      "Juvelyro dirbtuvėje karšta. Ne tik todėl, kad čia verda darbas, bet ir dėl to, kad kūrenasi krosnis. Smalsu kaip vykdavo juvelyro darbas? Pažiūrėk šį 360° video ir sužinok. Tik nepamiršk  žvalgytis aplinkui!",
      "It’s hot in the jeweller’s workshop. And that’s not just because the work is steaming here, but also because the furnace is lit. Curious how the work of a jeweler went? Watch this 360 ° video and find out. Just don’t forget to look around!"
    ]
  ]
];

export default videoTourText;
