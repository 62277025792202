const initialState = {
  imagesCategorySelected: 0
};

const setImagesCategory = (state = initialState, action) => {
  switch (action.type) {
    case "SET_IMAGES_CATEGORY":
      return { ...state, imagesCategorySelected: action.payload };
    default:
      return state;
  }
};

export default setImagesCategory;
