import React, { Component } from "react";
import { connect } from "react-redux";

import activeIcons from "../data/activeIconsData";

export class CategoryAbout extends Component {
  render() {
    if (this.props.contentType === "about")
      return (
        <div className="window-content window-content-about">
          <div className="overflow-wrapper">
            <div className="text-title title">{activeIcons[this.props.categoriesContentIndex][this.props.languageIndex]}</div>
            <div className="description">{activeIcons[this.props.categoriesContentIndex][this.props.languageIndex + 3]}</div>
          </div>
        </div>
      );
    else return null;
  }
}

const mapStateToProps = (state) => {
  return {
    languageIndex: state.setLanguage.languageIndex,
    categoriesContentIndex: state.setClickedContent.categoriesContentIndex,
    contentType: state.setClickedContent.contentType
  };
};

export default connect(mapStateToProps, null)(CategoryAbout);
