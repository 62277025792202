import React, { Component } from "react";
import { connect } from "react-redux";
import { setGalleryImageClicked } from "../actions";

export class WindowGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bigImageCarouselPosition: 1
    };
  }

  imageCarouselBigClicked = (direction) => {
    let currentImageCarouselPosition = this.state.bigImageCarouselPosition;
    let imagesCount = this.props.categoryImagesArray.length - 1;
    if (direction === -1 && currentImageCarouselPosition !== imagesCount) {
      this.setState({ bigImageCarouselPosition: currentImageCarouselPosition + 1 });
    } else if (direction === 1 && currentImageCarouselPosition !== 0) {
      this.setState({ bigImageCarouselPosition: currentImageCarouselPosition - 1 });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.categoryImageClicked !== prevProps.categoryImageClicked) {
      this.setState({ bigImageCarouselPosition: this.props.categoryImageClicked });
    }
  }

  render() {
    if (this.props.galleryImageClicked)
      return (
        <div className="modal-window-open-image">
          <div className="image-container">
            <div className="overflow-wrapper">
              <div className="images-carousel" style={{ left: `${this.state.bigImageCarouselPosition * -100}%` }}>
                {this.props.categoryImagesArray.map((image, index) => {
                  index++;
                  let fullImageUrl = this.props.categoryImageUrl + index + ".jpg";
                  return <div className="image" key={index} style={{ backgroundImage: `url("${fullImageUrl}")` }}></div>;
                })}
              </div>
            </div>
            <div
              className="btn btn-close"
              onClick={() => {
                this.props.setGalleryImageClicked();
              }}
            ></div>
            <div className="carousel-buttons">
              <div
                className="btn btn-left"
                style={{ opacity: this.state.bigImageCarouselPosition === 0 ? 0.3 : 1 }}
                onClick={() => {
                  this.imageCarouselBigClicked(1);
                }}
              ></div>
              <div
                className="btn btn-right"
                style={{ opacity: this.state.bigImageCarouselPosition === this.props.categoryImagesArray.length - 1 ? 0.3 : 1 }}
                onClick={() => {
                  this.imageCarouselBigClicked(-1);
                }}
              ></div>
            </div>
          </div>
        </div>
      );
    else return null;
  }
}

const mapStateToProps = (state) => {
  return {
    galleryImageClicked: state.setGalleryImageClicked.galleryImageClicked,
    categoryImageUrl: state.setGalleryImageClicked.categoryImageUrl,
    categoryImagesArray: state.setGalleryImageClicked.categoryImagesArray,
    categoryImageClicked: state.setGalleryImageClicked.categoryImageClicked
  };
};

const mapDispatchToProps = { setGalleryImageClicked };

export default connect(mapStateToProps, mapDispatchToProps)(WindowGallery);
