import React, { Component } from "react";
import { connect } from "react-redux";

import legendIconNames from "../data/legendIconNamesData";
import mapText from "../data/mapTextData";

export class MapLegend extends Component {
  render() {
    return (
      <div className="legend-container">
        <div className="legend-title">{mapText.legendTitle[this.props.languageIndex]}</div>
        <div className="icons-container">
          {legendIconNames.map((legendIconName, index) => {
            let iconName = legendIconName[this.props.languageIndex].replace("13th-14th c.", `<span>13th-14th c.</span>`);
            return (
              <div className="legend-item" key={index}>
                <div className="icon-container">
                  <div
                    className="icon"
                    style={{
                      backgroundImage: `url("./images/Asset ${index + 2}.png")`
                    }}
                  ></div>
                </div>
                <div className="name" dangerouslySetInnerHTML={{ __html: iconName }} />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    languageIndex: state.setLanguage.languageIndex
  };
};

export default connect(mapStateToProps, null)(MapLegend);
