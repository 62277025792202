const initialState = {
  languageIndex: 0
};

const setLanguage = (state = initialState, action) => {
  switch (action.type) {
    case "SET_LANGUAGE":
      return { ...state, languageIndex: action.payload };
    default:
      return state;
  }
};

export default setLanguage;
