import React, { Component } from "react";
import { connect } from "react-redux";

import CategoryWindowButtons from "./CategoryWindowButtons";
import CategoryAbout from "./CategoryAbout";
import CategoryGallery from "./CategoryGallery";
import CategoryVideo from "./CategoryVideo";
import CategoryTour from "./CategoryTour";

export class WindowCategories extends Component {
  render() {
    if (this.props.contentType !== "")
      return (
        <div className="modal-window-categories">
          <CategoryWindowButtons />
          <CategoryAbout />
          <CategoryGallery />
          <CategoryTour />
          <CategoryVideo />
          <div className="green-border"></div>
        </div>
      );
    else return null;
  }
}

const mapStateToProps = (state) => {
  return {
    contentType: state.setClickedContent.contentType
  };
};

export default connect(mapStateToProps, null)(WindowCategories);
