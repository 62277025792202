const initialState = {
  introAudioPlaying: false
};

const playIntroAudio = (state = initialState, action) => {
  switch (action.type) {
    case "PLAY_INTRO_AUDIO":
      return { ...state, introAudioPlaying: action.payload };
    default:
      return state;
  }
};

export default playIntroAudio;
