export const setLanguage = (selection) => {
  return {
    type: "SET_LANGUAGE",
    payload: selection
  };
};

export const playIntroAudio = (bool) => {
  return {
    type: "PLAY_INTRO_AUDIO",
    payload: bool
  };
};

export const setTourLink = (tourLink) => {
  return {
    type: "SET_TOUR_LINK",
    payload: tourLink
  };
};

export const setClickedContent = (categoryType, categoryIndex) => {
  return {
    type: "SET_CLICKED_CONTENT",
    payload: { type: categoryType, index: categoryIndex }
  };
};

export const setImagesCategory = (index) => {
  return {
    type: "SET_IMAGES_CATEGORY",
    payload: index
  };
};

export const setWindowWidth = (width) => {
  return {
    type: "SET_WINDOW_WIDTH",
    payload: width
  };
};

export const setGalleryImageClicked = (data) => {
  return {
    type: "SET_GALLERY_IMAGE_CLICKED",
    payload: data
  };
};

export const setCarouselPosition = (position) => {
  return {
    type: "SET_CAROUSEL_POSITION",
    payload: position
  };
};

export const setBrowserName = (browserName) => {
  return {
    type: "SET_BROWSER_NAME",
    payload: browserName
  };
};
