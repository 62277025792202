import React, { Component } from "react";
import { connect } from "react-redux";
import { setClickedContent, setImagesCategory, setCarouselPosition } from "../actions";

import GalleryTabs from "./GalleryTabs";

import mapText from "../data/mapTextData";

export class CategoryWindowButtons extends Component {
  render() {
    return (
      <div className="buttons-container">
        <div className="buttons-categories">
          <div
            className={`btn btn-about ${this.props.contentType === "about" ? "btn-clicked" : ""}`}
            onClick={() => {
              this.props.setClickedContent("about");
            }}
          >
            <div className="icon">
              <div className="text">{mapText.buttonAbout[this.props.languageIndex]}</div>
            </div>
          </div>
          <div className="line"></div>
          <div
            className={`btn btn-gallery ${this.props.contentType === "gallery" ? "btn-clicked" : ""}`}
            onClick={() => {
              this.props.setClickedContent("gallery");
            }}
          >
            <div className="icon"></div>
          </div>
          <div className="line"></div>

          <div
            className={`btn btn-tour ${this.props.contentType === "tour" ? "btn-clicked" : ""}`}
            onClick={() => {
              this.props.setClickedContent("tour");
            }}
          >
            <div className="icon"></div>
          </div>
          {this.props.categoriesContentIndex === 1 ? <div className="line"></div> : ""}
          {this.props.categoriesContentIndex === 1 ? (
            <div
              className={`btn btn-video ${this.props.contentType === "video" ? "btn-clicked" : ""}`}
              onClick={() => {
                this.props.setClickedContent("video");
              }}
            >
              <div className="icon"></div>
            </div>
          ) : (
            ""
          )}
        </div>
        <GalleryTabs />
        <div
          className="btn btn-close"
          onClick={() => {
            this.props.setClickedContent();
            this.props.setImagesCategory(0);
            this.props.setCarouselPosition([0, 0, 0]);
          }}
        >
          <div className="icon"></div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    languageIndex: state.setLanguage.languageIndex,
    categoriesContentIndex: state.setClickedContent.categoriesContentIndex,
    contentType: state.setClickedContent.contentType
  };
};

const mapDispatchToProps = {
  setClickedContent,
  setImagesCategory,
  setCarouselPosition
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryWindowButtons);
