const videoLinks = [
  ["https://video-turas.kernave.org/#media-name=odininkas", "https://video-turas.kernave.org/#media-name=odininkas_eng"],
  [
    "https://video-turas.kernave.org/#media-name=kauladirbis/tosininkas",
    "https://video-turas.kernave.org/#media-name=kauladirbis/tosininkas_eng"
  ],
  ["https://video-turas.kernave.org/#media-name=juvelyras", "https://video-turas.kernave.org/#media-name=juvelyras_eng"]
];

export default videoLinks;
