const tourLinks = [
  "https://turas7-12.kernave.org/#media-name=baltasis_kalnas_01",
  "https://turas9.kernave.org/",
  "https://turas7-12.kernave.org/#media-name=senosios_baznycios_vieta",
  "https://turas1-6.kernave.org/",
  "https://turas7-12.kernave.org/#media-name=medgrinda",
  "https://turas1-6.kernave.org/#media-name=Kernave_19",
  "https://turas7-12.kernave.org/#media-name=kernave_kapinynas",
  "https://turas7-12.kernave.org/#media-name=semeniskiu_kompleksas"
];

export default tourLinks;
