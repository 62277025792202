import React, { Component } from "react";
import { connect } from "react-redux";
import { setLanguage } from "../actions";

export class LanguagesSelection extends Component {
  render() {
    const languages = ["lt", "en"];
    return (
      <div className="languages-container">
        {languages.map((language, index) => (
          <div
            className={`btn btn-language-${language}  ${this.props.languageIndex === index ? "button-language-clicked" : ""}`}
            key={index}
            onClick={() => this.props.setLanguage(index)}
          >
            {language}
          </div>
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    languageIndex: state.setLanguage.languageIndex
  };
};

const mapDispatchToProps = {
  setLanguage
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguagesSelection);
