import React, { Component } from "react";
import { connect } from "react-redux";

import LanguagesSelection from "./LanguagesSelection";
import MapLegend from "./MapLegend";
import MapIcons from "./MapIcons";

export class InteractiveMap extends Component {
  render() {
    const interactiveMapHeight = this.props.contentType !== "" && this.props.windowWidth < 420 ? "interactive-map-container-height" : "";
    return (
      <div className={`interactive-map-container ${interactiveMapHeight}`}>
        <LanguagesSelection />
        <MapIcons />
        <MapLegend />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    contentType: state.setClickedContent.contentType,
    windowWidth: state.setWindowWidth.windowWidth
  };
};

export default connect(mapStateToProps, null)(InteractiveMap);
