import React, { Component } from "react";
import { connect } from "react-redux";
import { setWindowWidth, setBrowserName } from "./actions";

import InteractiveMap from "./components/InteractiveMap";
import WindowCategories from "./components/WindowCategories";
import WindowTour from "./components/WindowTour";
import WindowGallery from "./components/WindowGallery";

// "homepage": "https://robertasliekis.github.io/kernave/",
// "homepage": "http://zemelapis.kernave.org/",

export class App extends Component {
  handleResize = (e) => {
    const windowWidth = window.innerWidth;
    this.props.setWindowWidth(windowWidth);
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.getBrowserName();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  getBrowserName = () => {
    let browserName = "";
    if (navigator.vendor.match(/google/i)) {
      browserName = "chrome/blink";
    } else if (navigator.vendor.match(/apple/i)) {
      browserName = "safari/webkit";
    } else if (navigator.userAgent.match(/firefox\//i)) {
      browserName = "firefox/gecko";
    } else if (navigator.userAgent.match(/edge\//i)) {
      browserName = "edge/edgehtml";
    } else if (navigator.userAgent.match(/trident\//i)) {
      browserName = "ie/trident";
    } else {
      browserName = navigator.userAgent + "\n" + navigator.vendor;
    }
    this.props.setBrowserName(browserName);
  };

  render() {
    return (
      <div className="App">
        <div className="overflow-wrapper">
          <div className="website-wrapper">
            <InteractiveMap />
            <WindowCategories />
            <WindowTour />
            <WindowGallery />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  setWindowWidth,
  setBrowserName
};

export default connect(null, mapDispatchToProps)(App);
