import React, { Component } from "react";
import { connect } from "react-redux";
import { setClickedContent } from "../actions";

import activeIcons from "../data/activeIconsData";

export class MapIcons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iconHovered: false,
      iconHoveredIndex: 0,
      iconClickedIndex: 0
    };
    this.activeIconsRefs = [];
  }

  mouseEnterActiveIcon = (index) => {
    this.setState({ iconHovered: true, iconHoveredIndex: index });
  };

  mouseLeaveActiveIcon = () => {
    this.setState({ iconHovered: false });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.iconHovered !== prevState.iconHovered || this.state.iconHoveredIndex !== prevState.iconHoveredIndex) {
      this.activeIconsRefs[this.state.iconHoveredIndex].classList.remove("active-icon-stop");
      this.activeIconsRefs[prevState.iconHoveredIndex].classList.remove("active-icon-stop");
      this.activeIconsRefs.forEach((ref) => ref.classList.remove("icons-stop"));
      if (this.state.iconHovered) {
        this.activeIconsRefs[this.state.iconHoveredIndex].classList.add("active-icon-stop");
        this.activeIconsRefs.forEach((ref) => ref.classList.add("icons-stop"));
      }
    }
  }

  render() {
    return (
      <div className="map-images-container">
        <div className="river-image image"></div>
        <div className="map-image image"></div>
        <div className="active-icons-container">
          {activeIcons.map((activeIcon, index) => {
            return (
              <div
                className="active-icon"
                key={index}
                ref={(ref) => {
                  this.activeIconsRefs[index] = ref;
                }}
                onMouseEnter={() => this.mouseEnterActiveIcon(index)}
                onMouseLeave={() => this.mouseLeaveActiveIcon()}
                onClick={() => this.props.setClickedContent("about", index)}
              >
                <div className="icon-scale">
                  <div className="icon-rotation">
                    <div
                      className="icon"
                      style={{
                        backgroundImage: `url("./images/icon-spinning.png")`
                      }}
                    ></div>
                  </div>
                </div>
                <div className="icon-title">{activeIcon[this.props.languageIndex]}</div>
              </div>
            );
          })}
        </div>
        <div
          className="modal-window-overlay"
          style={{
            display: this.props.contentType !== "" ? "flex" : "none"
          }}
        ></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    languageIndex: state.setLanguage.languageIndex,
    categoriesContentIndex: state.setClickedContent.categoriesContentIndex,
    contentType: state.setClickedContent.contentType
  };
};

const mapDispatchToProps = {
  setClickedContent
};

export default connect(mapStateToProps, mapDispatchToProps)(MapIcons);
