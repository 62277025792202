const initialState = {
  contentType: "",
  categoriesContentIndex: 0
};

const setClickedContent = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CLICKED_CONTENT":
      let data = action.payload;
      let index, type;
      if (data.type === undefined && data.index === undefined) {
        type = "";
        index = 0;
      } else if (data.type !== undefined && data.index === undefined) {
        type = data.type;
        index = state.categoriesContentIndex;
      } else {
        type = data.type;
        index = data.index;
      }
      return { ...state, contentType: type, categoriesContentIndex: index };
    default:
      return state;
  }
};

export default setClickedContent;
