const activeIcons = [
  [
    "Baltasis kalnas",
    "The White Hill",
    8,
    `Kadaise Neris buvo daug vandeningesnė nei dabar. Jos vandens jėga suformavo platų slėnį, kurį mes dabar vadiname Pajautos vardu. Suformavusi šį slėnį, upė toliau išgraužė vagą į šiaurės vakarus pro statų skardį, vadinamą Baltuoju kalnu. Nuo jo atsiveria kvapą gniaužiantis vaizdas į upės vingį ir miškingą kraštovaizdį, kuris turbūt mažai pakito nuo to laiko, kai pro čia traukė kryžiuočių pulkai. Iš kryžiuočių kelių aprašymų žinomi buvę penki keliai, einantys pro Kernavę. Šiuose užrašuose taip pat minimos brąstos – seklios vietos upėse, per kurias galima persikelti į kitą krantą – viena iš jų buvo prie Baltojo kalno. 
Dabar šis skardis – tai ir pažintinio tako pradžia. Vingiuodamas per mišką palei srauniąją Nerį jis eina per pušyną, palei Lielupės upelį ir Valiukiškių pilkapyną.
`,
    `Once the Neris River was more watery than it is now. The force of its waters formed a wide valley, which we now call Pajauta. Once the valley was formed, the river was making its way to the Northwest by the steep bank that we now call Baltasis Kalnas (the White Hill). It opens a breath-taking view of the river curve and the woodland landscape, which probably did not change much since the crusaders were marching by this place. Five roads that led through Kernavė are known from the descriptions of roads that were done by the crusader spies. These records also mentions fords - one of them was very close to the White Hill. 
Now this steep bank is the beginning of a hiking trail. Winding through the forest it goes by the Neris River, through a pleasant pine forest and Valiukiškiai barrow mounds.
`
  ],
  [
    `XIII-XIV a. Kernavės amatininkų
    kiemų rekonstrukcijos`,
    "Reconstructed craftsmen yards from the 13th - 14th c.",
    30,
    `Kernavė ne retai vadinama „Lietuvos Troja“ – 1986 m. archeologinių tyrimų metu buvo aptikta sudeginto medinio miesto liekanų. Tačiau tai, kas liko po Kernavės gaisro, labai gerai užsikonservavo drėgnoje Pajautos slėnio dirvoje ir išliko iki mūsų dienų. Dėl šios priežasties archeologams pavyko surinkti daug ir išsamių duomenų apie mieste buvusius pastatus, jų aplinką, vidaus interjerą. Šios žinios paskatino sukurti tyrinėtų amatininkų kiemų rekonstrukcijas. Tyrimų duomenys leido nustatyti, kad šiuose kiemuose viduramžiais gyveno ir paveldimu amatu vertėsi net kelios juvelyrų, kalvių ir rago apdirbėjų kartos. Šiuose kiemuose atkurti pastatai ir jų interjeras reprezentuoja šiuos amatus. Kiekvienas kiemas yra skirtas vieno iš amatų – juvelyro, kauladirbio ar kalvio – rekonstrukcijai. Be gyvenamojo namo kiemuose buvo dirbtuvės, kuriuose šie specializuoti amatininkai darbavosi, ir papildomi pastatai – tvarteliai, klėtys ir pašiūrės. 
Viduramžių Kernavė – tai medinio užstatymo miestas, kuriame klestėjo amatininkų, pirklių ir karių bendruomenė. XIII–XIV a. miestas turėjo gerai įtvirtintų piliakalnių gynybinę sistemą, susiformavusį kiemų ir gatvių tinklą. Kiemus nuo gatvės ir kaimynų skyrė vertikalių lentų ir rąstų arba iš šakų pintos tvoros. Atkurtas to meto Kernavės fragmentas – tai bandymas rekonstruoti ankstyvosios miesto bendruomenės gyvenamąją aplinką.
`,
    `Kernavė is often called “Lithuanian Troy”. This is because remains of razed wooden town were discovered in 1986. However, everything that survived the burning, were preserved in the wet turfs of Pajauta valley and survived to this day. Because of that, archaeologist have gathered a lot of abundant data about buildings, theirs surroundings and interiors that existed in the town. This information had led to a reconstruction of the excavated craftsmen yards. The data of research allows to constitute that several generations of jewellers, blacksmiths and bone carvers have lived and worked in those yards. These crafts are represented in the craftsmen yards with reconstructed houses and their interiors. Each yard was dedicated to the reconstruction of one of the crafts - jeweller, bone carver and blacksmith. Beside the living house, there were workshops, where these specialised craftsmen worked, and ancillary buildings - animal sheds, food stored and shacks. 
Medieval Kernavė was the town with wooden constructions and a thriving society of craftsmen, merchants and warriors lived there. Yards were separated from the street and neighbours by a fence, made from upright planks or poles, or intertwined branches of trees. Recreated fragment of the medieval Kernavė is an attempt to reconstruct the living surroundings of a medieval town society.
`
  ],
  [
    "Senosios bažnyčios vieta",
    "The place of the old Church",
    60,
    `1390-aisiais Kernavės miestas buvo sudegintas. Tačiau gyvenimas šioje vietoje nenutrūko – buvo tik apleistas nepatogus gyvenimui slėnis ir neatstatytos pilys ant piliakalnių. Visas gyvenimas persikėlė ant viršutinės Pajautos slėnio terasos, ten kur ir dabar įsikūręs Kernavės miestelis. Manoma, kad čia taip pat buvo pastatyta pirmoji Kernavės bažnyčia, kurią 1420 metais įkūrė Vytautas Didysis. Kernavėje taip pat buvo įkurtas ir kunigaikščio medžioklės dvaras – žinomi iš čia rašyti Vytauto laiškai Kryžiuočių ordino magistrams. Kernavės žemė savo svarbos neprarado ir po Vytauto mirties. Iš čia kilę ir garsiųjų Radvilų protėviai – Astikų giminė. Kernavę valdė garsios LDK ir Abiejų Tautų respublikos giminės – Budraičiai, Hlebavičiai, Bžostovskiai, Bialozorai. Paskutiniai Kernavės valdytojai – Riomerių giminė – įkūrė čia tebestovinčią mūrinę koplyčią, o medinė koplyčia buvo perkelta čia iš XVIII a. Kernavėlės dvaro. 
XVII a. vietoje,  kurioje dabar matosi pamatų perimetras, buvo  pastatyta bažnyčia, kuri vėliau dar keletą kartų perstatyta, o paskutinė medinė bažnyčia išrinkta ir pergabenta į Krivonis, Kaišiadorių rajone. 1920 m. Kernavėje buvo funduota nauja neogotikinė bažnyčia, kurią visa bendruomenė statė keletą metų. 
`,
    `In 1390, the town of Kernavė was razed. However, life did not completely cease in this place - only a valley was abandoned and the forts were not rebuilt on the hills. Everything moved up on the upper terrace of Pajauta valley, the place where the modern town of Kernavė stands now. It is believed that the first church in Kernavė was built by Vytautas the Great in 1420. There was also a Grand duke’s hunting manor - letter to the Grand Masters of the Teutonic order are know that were written from this manor. The land of Kernavė didn’t lose its significance even after the dearth of Vytautas. The family of Astikai - the forefathers of the renowned Radvilos - descended from Kernavė. Kernavė was also ruled by many renowned family of the Grand Duchy of Lithuania and (later) Lithuanian-Polish Commonwealth: Budraičiai, Hlebavičiai, Bžostovskiai, Bialozorai. The last rulers of Kernavė - the family of Romers - built a brick chapel that still stands here. The wooden chapel was moved here from Kernavėlė manor of the 18th century. 
In the 17th century, in the place now marked by a stone perimeter, a church was built. Later it was rebuilt after several fires, and the last church was dismantled and moved to Krivonys, Kaišiadoriai district, where is stands to this day. In 1920, the new neogothic church was funded by the local community and it was built by 1925. 
`
  ],
  [
    `Viduramžių Kernavės įtvirtinto
    miesto liekanos`,
    `The remains of the medieval fortified town of Kernavė`,
    100,
    `Visa Lietuvos teritorija nusėta piliakalniais, tačiau Kernavės archeologinė vietovė išskirtinė – čia galime išvysti net penkis piliakalnius. Keturi iš jų įkurti vienas šalia kito ir sudaro bendrą sistemą. Turbūt įprasta manyti, kad piliakalniuose buvo statomos tik pilys, tačiau Kernavėje – tai senojo miesto likučiai, XIII–XIV a. jie buvo čia gyvavusio miesto centrine dalimi. Piliakalniai, tiksliau ant jų stovėjusios pilys ir įtvirtinta gyvenvietė sudarė miesto šerdį. Pagrindinis piliakalnis buvo šiuo metu žemiausias Aukuro kalnas. Jame buvo įkurta kunigaikščio pilis. Pilies kalno piliakalnyje gyveno pirkliai, darbavosi amatininkai. Šis piliakalnis kartu su Mindaugo sosto ir Lizdeikos kalno piliakalniais atliko ir gynybinę funkciją – iš šiaurės bei vakarų jį supo gilus griovys bei pylimas. Šių trijų piliakalnių fortifikacinis kompleksas saugojo kunigaikščio rezidencijos prieigas – juose gyveno kunigaikščio kariaunos vyrai. Mieste dominavusį piliakalnių gynybinį kompleksą iš visų pusių supo apgyvendintos teritorijos su mediniais pastatais, aptvertais kiemais ir gatvėmis. Viduramžių Kernavė – amatininkų ir pirklių miestas, kuris plytėjo Pajautos slėnyje ir viršutinėje Neries terasoje. XIII–XIV a., miesto klestėjimo laikais, jame galėjo gyventi apie 2000 miestelėnų. 
XIII a. antra pusė – XIV a. pradžia – tai iškiliausias Kernavės gyvavimo laikotarpis, kuomet ji tapo vienu iš svarbiausių Lietuvos politinių, administracinių ir ekonominių centrų.  Tuo metu miesto dydžiu, svarba bei gynybinių įtvirtinimų pajėgumu jai galėjo prilygti tik Vilnius. Gal todėl Kernavė minima kaip legendinė pirmoji Lietuvos sostinė. XIV a. valstybės sostine tapus Vilniaus miestui, Kernavė ankstesnę svarbą pamažu prarado. 1390 metų kryžiuočių antpuolis Kernavei buvo lemtingas – jo metu sudegintas miestas ir piliakalnių gynybinė sistema jau niekada nebuvo atstatyti. Tačiau Kernavės piliakalniai iki šiol mena mūsų senosios valstybės didybę ir galią.
`,
    `The whole territory of Lithuania is doted by the hill-forts but Kernavė here is exceptional - you can see five hill-forts in one place. Four of them stand one next to each other and comprise a unified system. It is usual to think that hill-forts were meant to have castles on them, but in Kernavė - these are the remains of the old town from the 13th and 14th centuries. These hill-forts were the central part of the town that once thrived here. Hill-forts, or rather the castles and a fortified settlement that stood on them were the heart of the town. The main hill-fort was the smallest one - Aukuras hill. Duke’s castle was built on it. Castle hill was a fortified settlement where merchants lived and craftsmen worked. This hill-fort together with the hill-forts of Mindaugas’ Throne and the Hill of Lizdeika had also a defensive purpose - a moat surrounded it from the northern and western part. The fortification complex of these three hill-forts protected the residence of the duke - men of the duke’s retinue were stationed there. The dominant fortification complex of hill-forts was surrounded by the urban areas with wooden buildings, fenced yards and streets. Medieval Kernavė was the town of craftsmen and merchants. It lay in the Pajauta valley and the upper terrace of the Neris river. In the 13th and 14th centuries, in the days of its height, about 2,000 inhabitants might have lived in the town. 
13th and 14th centuries are the most important period in the history of Kernavė. Then it was one of the most important political, administrative and economical centres of Lithuania.  Only Vilnius could be compared by the size of the town and by the importance of the fortified defences. May be for that reason Kernavė is thought to be the legendary first capital of Lithuania. At the beginning of the 14th century, the capital was moved to Vilnius and Kernavė started loosing its former importance. The crusader attack of 1390 was fatal for the town of Kernavė - it, together with fortified system on the hill-forts, was burned and never rebuilt again. Still the hill-forts remain the signs of the glory and power of the ancient state.
`
  ],
  [
    "Medgrinda",
    "The oldest paved road in Lithuania",
    150,
    `Lietuvos kelių istorija sena, tačiau tyrinėti ją be galo sunku, nes daiktinių įrodymų apie praeities kelius aptinkama itin mažai. Ko gero, pirmieji gyventojai į Lietuvos teritoriją atklydo gyvūnų pramintais takais. Ilgus tūkstantmečius patogiausia kelionė išliko vandeniu – upėmis, ežerais, jūra. Vėliau atsiradę  sausumos takai tebuvo neplatūs vieškeliai, neturėję kietos dangos. Mokslininkai iki šiol mano, kad ilgą laiką „kelias“ buvo suvokiamas kaip keliavimo kryptis, o ne fiziškai egzistavęs daiktas. Tik atradus senovinius kelius kieta danga galima tyrinėti jų kryptis ir atsiradimo priežastis.
Spėjama, kad seniausias kelias kieta danga Lietuvoje yra medgrinda Pilies kalno piliakalnio papėdėje, Pajautos slėnyje. Jį radę archeologai teigia, jog medgrinda įrengta III–V amžiuje. Tai 2,30 – 2,40 m pločio ir apie 100 m ilgio kelias, dengtas tankiai suguldytais 10–12 cm skersmens pušiniais rąstais. 
Kelias čia atsirado neatsitiktinai, jo labai reikėjo vietiniams gyventojams. Vieta, per kurią ėjo šis kelias – buvusi Neries senvagė – pelkėta ir šaltiniuota, o gyvenvietės III–V a. buvo įkurtos ir slėnyje, ir aukštai ant slėnio terasų (kur vėliau buvo suformuoti piliakalniai). Nukeliauti iš vienos gyvenvietės į kitą, nepaisant to, kad jas skyrė vos keli šimtai metrų, nebuvo lengva. Reikėdavo dideliu lankstu aplenkti pelkę. Gali būti, kad tai ir paskatino nutiesti kelią per šią vandens kliūtį. 
`,
    `It is very difficult to research the history of roads in Lithuania, because there is so few material evidence from the past. Probably the first people were travelling to Lithuanian territory by animal trails. Many centuries the most convenient travel was by water - rivers, lakes and sea. Later land roads appeared that were mere trails, and they had no paving. Historians still think that “road” in the distant past was understood as the travelling direction, not a physical thing. Only when the old roads with paving are found, we can research their directions and purposes.
It is believed that the oldest paved road in Lithuania lies at the bottom of the Castle hill in Pajauta valley - it is the wood paved road, called “medgrinda”. Archaeologists who discovered it claim it to be from 3th to 5th century. This road is about 100 meters in length and 2.30-2.40 m at its widest. It is paved by pine logs of 10-12 cm in diameter and put together very tightly. 
The road was built here on purpose, it was very desired by the local people. The place that this road leads through is the old river bed of Neris. It is very wet and boggy. In 3rd to 5th centuries settlements were spread both near the river and on the upper terrace (where the hill-forts stand now). To travel from one settlement to another was not easy, even though they a few hundred meters away from each other. One needed to travel around the bog by a long road. Maybe this was the purpose for this paved road to be built - it was much easier to cross this watery place. 
`
  ],
  [
    "Kernavės nekropolis",
    "The necropolis of Kernavė",
    10,
    `Penktasis Kernavės piliakalnis – paslaptingiausias iš visų. Įkurtas prie XX a. išnykusio Kriveikiškio kaimo, kiek atokiau nuo buvusio Kernavės senojo miesto. Jo paskirtis nėra visiškai aiški, jį tyrę archeologai aptiko vos keletą geležies amžiaus puodų šukių, laužaviečių ir dviejų masyvių stulpaviečių vietas. Tačiau šis piliakalnis sergsti visai čia pat, vos už 100 metrų palaidotų kernaviškių ramybę. Archeologai ištyrė dar tik dalį didžiulio viduramžių kapinyno – beveik 300 kapų. Apskaičiuota, kad jame palaidota apie 1500 XIII a. pabaigos – XIV a. miestelėnų. Kapinyne turtingomis įkapėmis išsiskiria moterų kapai, kuriuose neretai mirusioji laidota padabinta sidabriniu paauksuotu apgalviu, auskarais, kaklo vėriniu, sidabriniais žiedais. Vyrų kapuose nerasta ginklų, matyt, čia ilsisi eiliniai Kernavės miestelėnai – amatininkai, pirkliai ir jų šeimų nariai. Kur palaidoti to meto Kernavę garsinę karių luomo atstovai, didikai, kunigaikščiai, dar nėra žinoma.
Antropologams šio kapinyno medžiaga taip pat pateikė įdomių duomenų. Išanalizavę archeologų surinktus duomenis jie nustatė, kad kernaviškiai buvo neaukšti, kresni, tvirtų kaulų žmonės. Tačiau gyvenimas mieste viduramžiais nebuvo nei malonus, nei patogus. Dėl prasto maisto, medicininių žinių ir higienos stokos miestelėnai gyveno žymiai trumpiau nei dabar. Pažeidžiamiausi būdavo vaikai – kone pusė visų vaikų Kernavėje nesulaukdavo pilnametystės. Tačiau ir sulaukę pilnametystės, moterys vidutiniškai tenugyvendavo 38, o vyrai – 40 metų. Penkiasdešimtmečių ir vyresnių viduramžių Kernavėje iš maždaug 2000 gyventojų galėjo būti tik apie 10 vyrų ir apie 20 moterų. 
Šis kapinynas įdomus ir dar vienu aspektu – žmonės čia laidoti neįprastai, net ir viduramžių Lietuvos požiūriu. Iki šio kapinyno tyrimų buvo manyta, kad mirusiuosius viduramžių Lietuvoje degindavo, tačiau Kernavėje palaidoti nesudeginti kūnai. Jie buvo guldomi į lentomis įrėmintą kapo duobę, orientuoti maždaug į vakarus, nors ne taip griežtai kaip krikščioniškajame laidojime. Įkapės taip pat rodo, kad kapuose palaidoti ne krikščionys – įkapių dėjimas yra būdingas senajam baltų tikėjimui. Taigi, šiame kapinyne susipina dviejų kultūrų – pagoniškosios ir krikščioniškosios – bruožai. Pastaruoju metu ir kitose Lietuvos vietose (pavyzdžiui, Vilniuje) aptinkama panašių laidojimo paminklų. Tai – daugiatautės bendruomenės, kurioje gyveno įvairių tikėjimų ir kultūrų žmonės ir kurioje po truputį buvo perimamos „naujos“ krikščioniškos tradicijos, bruožas. 
`,
    `The fifth hill-fort of Kernavė is the most mysterious one. It was established near now extinct village of Kriveikiškis, at a bit of a distance from the town of Kernavė. Its purpose is not quite clear, archaeologist who excavated here found only a few sherds from the Iron Age, several fireplaces and two massive pole holes. However, this hill-forts guards the peace of the deceased of medieval Kernavė. A cemetery was discovery mere 100 meters away from this hill-fort. Archaeologists have excavated only a part of this cemetery - about 300 graves. But it is estimated that about 1,500 graves from 13th and 14th centuries might be in this cemetery. Women graves stand out from the others by the richness of the grave goods. Many of the deceased women were buried with gilded silver headband, earrings, necklace, and silver rings. Men graves don’t have weapons, which means that low born townsfolk are buried here. These might have been craftsmen, merchants and their family members. It is not known where the higher society - the nobles and dukes of Kernavė are buried.
The material of this cemetery also gave many insights for physical anthropologists. After analysing the gathered evidence, they have established that the townspeople of Kernavė were small in stature, stocky and strong-boned. However, life in medieval town was neither pleasant, nor convenient. Poor food, lack of medical knowledge and hygiene were the main reasons of short life (compared to modern society). Children were the most vulnerable - almost half of the children in Kernavė didn’t reach puberty. Even those who reached it, might have enjoyed only short lives: women would reach up to 38, men - up to 40 years of age. Out of 2000 inhabitants of medieval Kernavė only about 10 men and 20 women were older than fifty. 
This cemetery is interesting from another aspect - people are buried here in an unusual manner, even in medieval Lithuanian standards. Until the research in this cemetery, it was thought that deceased in medieval Lithuania were cremated, but the buried in Kernavė are not cremated. The bodies were laid in to a coffins made of planks and put into the grave, they were positioned roughly to the west, but not as strict as in Christian burials. Grave goods show that people who were buried there are not Christians - grave goods are sings of the ancient Lithuanian beliefs. So this cemetery holds the characteristics of two cultures - Christian and Pagan. Recently similar cemeteries were discovered in other places in Lithuania (Vilnius, for example). This is the characteristics of a poly-ethnic society, where people of different cultural and religious backgrounds lived together and “new” Christian beliefs were absorbed into the old belief system. 
`
  ],
  [
    "Kernavės kapinynas",
    "The burial ground of Kernavė",
    250,
    `Seniausią Kernavės kapinyną žymi išdėstyti akmenys. Jie žymi rastas I tūkstm. pr. Kr. palaidojimo vietas. Šis kapinynas priskiriamas Brūkšniuotosios keramikos kultūrai, kurios žmonės laikomi lietuvių protėviais. Ji buvo išplitusi Rytų Lietuvos, Pietryčių Latvijos bei Vidurio ir Vakarų Baltarusijos teritorijose. Iki šio unikalaus archeologinio paminklo atradimo 1989 metais, apie Brūkšniuotosios keramikos kultūros laidoseną visiškai nieko nežinota. Buvo keliama įvairiu hipotezių, kad žmonės galėjo būti laidojami vandenyje, specialiuose antžeminiuose statiniuose arba net medžiuose. Kernavės degintinis kapinynas ir šiandien tebėra vienintelis Lietuvoje plačiau tyrinėtas Brūkšniuotosios keramikos kultūros laidojimo paminklas. Kitur aptinkama tik suardytu pavienių kapų ir tai – labai retai.
Šiame kapinyne sudeginti mirusiųjų palaikai laidoti trejopai: paprastoje duobelėje, duobėje įrengtoje akmenų krūsnyje arba keraminėje urnoje, o viename iš kapų mirusysis buvo palaidotas nesudegintas. Įkapės negausios – rastas bronzinis smeigtukas su įvijine galvute, įvijinis antsmilkinis, kepurėlės grandelės. Ištyrus kapuose aptiktus degintinius kaulus nustatyta mirusiųjų lytis, gyvenimo trukmė ir patologijos. Čia aptiktas vienas seniausių gydymo įrodymas – lūžęs, bet sugijęs rankos kaulas. Konstatuota, kad krūsniniuose kapuose dažniausiai buvo laidojami vyrai, paprastose duobelėse – moterys, o urnose – vaikai. Šioje ekspozicijoje kapų vietos pažymėtos tik simboliškai. Kaip kapai buvo žymimi prieš kelis tūkstančius metų, nėra žinoma – iki mūsų dienų jokių antžeminių konstrukcijų neišliko.
`,
    `The stones mark the oldest burial ground in Kernavė. They mark the burial places from 1,000 years BC. This cemetery is attributed to the Stroke Pottery Culture; the people of this culture are the direct ancestors of Lithuanians. It has spread through the Eastern Lithuania, South-Eastern Latvia and Central and Western Belarus. Until this unique archaeological monument was discovered in 1989, nothing at all was known about the burial traditions of Stroke Pottery Culture. There were even hypothesis that people might have been buried in water, specialized buildings above the ground or even in trees. The cremation burial of Kernavė is still the only burial site in Lithuania related to the Stroke Pottery Culture that was so thoroughly investigated. Discovered burials in other sites usually are scattered and even those are rare.
The remains of the buried were laid down in three ways: in a simple hole, in a kern of stones or in a ceramic urn. There was one burial with uncremated remains. The grave goods were scarce - a bronze pin with a spiral head, spiral temple ring and some chain links from a cap ornament were discovered. After investigating the cremated bones, the sex, life longitude and pathologies were established.  Even one of the oldest evidence of a medical treatment - a fractured and healed bone of an arm - was discovered. It has been established that men were mostly buried in a kern type burials, women were buried in simple holes, while children were buried in urns. The stone in this open air exhibition are put here only for symbolic purposes. How the graves were marked several thousand years ago is not known.
`
  ],
  [
    `Semeniškių kapinynas
  ir gyvenvietė`,
    "Semeniškės burial site and settlements",
    300,
    `Nuo kalvelės šalia Semeniškių kaimo atsiveria puikus vaizdas į Kernavės piliakalnius. Pats kaimas yra įsikūręs ant Neries kranto Pajautos slėnyje. Tai būtų buvusi niekuo neypatinga vieta, jeigu vienas iš čia gyvenančių žmonių nebūtų sugalvojęs perstatyti savo gyvenamųjų namų. Pradėjus archeologinius tyrimus, vos 10 cm gylyje po namu buvo aptikti senovės kapų. Tai III–IV a. pilkapiai, kuriuose sulaidoti mirusieji buvo nedeginti, su gausiomis įkapėmis. Moterų kapuose blizgėjo apyrankių, antkaklių, segių ir kitų papuošalų bronza. Vyrų kapuose daugiausia aptikta darbo įrankių ar ginklų.  
Šioje vietoje buvo surasta ir kitų, įvairių laikotarpių archeologinių paminklų. Didžiausias jų – tai geležies amžiaus Semeniškių gyvenvietė, įkurta visai šalia buvusio pilkapyno. Šią gyvenvietę puoselėjo daugiau kaip tūkstantį metų čia gyvavusi bendruomenė. Šalia šios gyvenvietės teka Kernavėlės upelis, greičiausiai suteikęs vardą visai vietovei. Šio upelio senoje vagoje archeologai aptiko dar vieną kapinyną. Čia per ilgą vagos atkarpą buvo išsimėtę sudeginti kaulai. Daiktų aptikta nedaug, tačiau aptikta monetų, leidžiančių datuoti šį kapinyną XIV a. pabaiga. Taigi, tiek šis, tiek Kernavės-Kriveikiškio kapinynas buvo naudojami vienu metu. Mirusiųjų deginimas – būdingas senojo lietuvių tikėjimo bruožas. Taigi, čia galėjo būti laidojami išlaikę senąjį tikėjimą miestelėnai, o Kernavės-Kriveikiškio kapinyne – priėmę krikščionybę arba kitataučiai. Neatmestina tikimybė, kad Semeniškių degintinis kapinynas galėjo būti įrengtas ir dėl to, kad vienu metu žuvo daug žmonių. Tai galėjo būti karo ar epidemijos (pavyzdžiui, maro) pasekmė.
`,
    `A hill near Semeniškės villages opens up a magnificent view upon the Kernavė hill-forts. The village itself is situated on the bank of the Neris River, in the Pajauta valley. It would have stayed an unexceptional place if one of the inhabitants of the village would not have decided to rebuild his houses. Just after starting the archaeological excavations, old graves were found just 10 cm below the floor of the house. These were the barrow mounds from the 3rd and 4th century where the dead were not cremated and had abundant grave goods. Women graves were glittering with the bronze of bracelets, neckrings, brooches and other jewellery. Men graves had a lot of tools and weapons.  
There were also other archaeological monuments from various periods of time. The largest of them is a settlement from the Iron Age; it was established very close to the burial mounds. This settlement was inhabited by the society who did not leave this place for a thousand years. There is a Kernavėlė rivulet flowing just outside the settlement; this rivulet might have given its name to the whole region. Archaeologists have discovered one more burial site in one of its river beds. Bones were scattered in the long stripe of its old river bed. There weren’t many items discovered, but some coins were found; they show that this burial site might have been in use in the 14th century. So, this burial site and that of Kernavė-Kriveikiškis were used at the same time. Cremation of the dead is the characteristics of the old Lithuanian religion. So here townspeople who kept the old faith might be buried, while Kernavė-Kriveikiškis was the burial place for baptised and people from other nationalities. There is also a possibility that Semeniškės cremation burial was created because there was a catastrophic death rate in the population. This might have been caused by war or epidemics (for example, plague).
`
  ]
];

export default activeIcons;
