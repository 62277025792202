import React, { Component } from "react";
import { connect } from "react-redux";
import { setTourLink } from "../actions";

export class WindowTour extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.iframeRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.tourLink !== prevProps.tourLink && this.props.browserName !== "safari/webkit") {
      console.log(this.props.tourLink);
      if (this.props.tourLink !== undefined) {
        this.iframeRef.current.requestFullscreen();
      }
      //else {
      // document.exitFullscreen();
      // }
    }
  }

  render() {
    if (this.props.tourLink !== undefined) {
      return (
        <div className="tour-window-container" ref={this.iframeRef}>
          {console.log("tour window")}
          <div className="tour-window-content">
            <div className="btn btn-close" onClick={() => this.props.setTourLink()}></div>
            <div className="virtual-reality-frame-container">
              <iframe
                // src={this.props.tourOpen ? `${this.props.tourLink}` : ""}
                src={this.props.tourLink}
                //src={"https://testinis.kernave.org/#media-name=Alytus_10"}
                title="virtual tour"
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      );
    } else return null;
  }
}

const mapStateToProps = (state) => {
  return {
    tourLink: state.setTourLink.tourLink,
    browserName: state.setBrowserName.browserName
  };
};

const mapDispatchToProps = {
  setTourLink
};

export default connect(mapStateToProps, mapDispatchToProps)(WindowTour);
