import React, { Component } from "react";
import { connect } from "react-redux";
import { setImagesCategory, setCarouselPosition } from "../actions";

import galleryImages from "../data/galleryImagesData";

export class GalleryTabs extends Component {
  render() {
    const displayedImages = galleryImages[this.props.categoriesContentIndex];
    return (
      <div
        className="buttons-gallery-tabs"
        style={{ display: this.props.contentType === "gallery" && displayedImages.length > 1 ? "flex" : "none" }}
      >
        {displayedImages.map((imagesCategory, index) => {
          let zIndexArray;
          switch (this.props.imagesCategorySelected) {
            case 0:
              zIndexArray = [4, 3, 2, 1];
              break;
            case 1:
              zIndexArray = [3, 4, 3, 2];
              break;
            case 2:
              zIndexArray = [2, 3, 4, 3];
              break;
            case 3:
              zIndexArray = [1, 2, 3, 4];
              break;
            default:
              break;
          }
          let grayScaleValue = (zIndexArray[index] - 4) * -1 * 25;
          return (
            <div
              className="btn btn-tabs"
              key={index}
              style={{
                filter: `grayscale(${grayScaleValue}%)`,
                left: this.props.windowWidth > 420 ? `calc(100% + ${-24 * index}px)` : `calc(100% + ${-20 * index}%)`,
                zIndex: zIndexArray[index],
                width: this.props.categoriesContentIndex === 5 ? "200px" : "130px"
              }}
              onClick={() => {
                this.props.setImagesCategory(index);
                this.props.setCarouselPosition([0, 0, 0]);
              }}
            >
              <div className="tab-icons" style={{ display: this.props.imagesCategorySelected === index ? "none" : "flex" }}>
                <div className="icon"></div>
                <div className="icon"></div>
              </div>
              <div className="tab-title" style={{ display: this.props.imagesCategorySelected === index ? "flex" : "none" }}>
                {imagesCategory[3] !== undefined ? imagesCategory[3][this.props.languageIndex] : null}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    languageIndex: state.setLanguage.languageIndex,
    categoriesContentIndex: state.setClickedContent.categoriesContentIndex,
    contentType: state.setClickedContent.contentType,
    imagesCategorySelected: state.setImagesCategory.imagesCategorySelected,
    windowWidth: state.setWindowWidth.windowWidth
  };
};

const mapDispatchToProps = { setImagesCategory, setCarouselPosition };

export default connect(mapStateToProps, mapDispatchToProps)(GalleryTabs);
